import { BaseElement, BaseText, Descendant, Editor, NodeEntry, Point, Range, Text } from 'slate';
import { ReactEditor } from 'slate-react';
import { filterValues } from '../../../utils/filtering';
import {
  IBaseSyntaxHighlightInputState,
  IAutocompleteSuggestion, IHighlight,
} from './BaseSyntaxHighlightInput';

export const getAutocompleteForCurrentSelection = (editor: ReactEditor, autocompleteSuggestions: ReadonlyArray<IAutocompleteSuggestion>): IBaseSyntaxHighlightInputState => {
  const { selection } = editor;

  if (!autocompleteSuggestions || !selection || !Range.isCollapsed(selection)) {
    return null;
  }

  const [cursorLocation] = Range.edges(selection);
  const wordStart = Editor.before(editor, cursorLocation, { unit: 'word' });
  const wordEnd = wordStart && Editor.after(editor, wordStart, { unit: 'word' });

  if (!wordEnd || !Point.equals(wordEnd, cursorLocation)) {
    return null;
  }

  const beforeRange = wordStart && Editor.range(editor, wordStart, cursorLocation);
  const rawBeforeText = beforeRange && Editor.string(editor, beforeRange);
  const beforeText = rawBeforeText && rawBeforeText.match(/([a-zA-Z]+)$/)?.[0];
  const matchingSuggestions = beforeText && filterValues(autocompleteSuggestions, beforeText, true);

  if (!matchingSuggestions || matchingSuggestions.length === 0) {
    return null;
  }

  let adjustedRange = beforeRange;
  if (beforeText.length < rawBeforeText.length) {
    const startPoint = Editor.before(editor, cursorLocation, { distance: beforeText.length });
    adjustedRange = Editor.range(editor, startPoint, cursorLocation);
  }

  const start = Range.start(adjustedRange);
  const domRange = ReactEditor.toDOMRange(editor, { anchor: start, focus: start });
  const rect = domRange.getBoundingClientRect();

  return {
    autocompleteTarget: adjustedRange,
    autocompleteSuggestions: matchingSuggestions,
    autocompleteSuggestionIndex: 0,
    autocompleteAnchorPosition: {
      x: rect.x - 5,
      y: rect.bottom + 15,
    },
  };
};

export const decorate = (entry: NodeEntry, nextHighlight: (fromIndex: number) => IHighlight | null) => {
  if (!Text.isText(entry[0])) {
    return [];
  }
  if (entry[0].text?.length > 0 && nextHighlight) {
    let ranges: Range[] = [];
    let highlight = nextHighlight(0);
    while (highlight != null) {
      ranges = ranges.concat({
        ...highlight.style,
        anchor: { path: entry[1], offset: highlight.startIndex },
        focus: { path: entry[1], offset: highlight.startIndex + highlight.length },
      });
      highlight = nextHighlight(highlight.startIndex + highlight.length);
    }
    return ranges;
  }
  return [];
};

export const textFromModel = (model: Descendant[]): string => {
  return ((model[0] as BaseElement).children[0] as BaseText).text;
};

export const valueToModel = (value: string) => {
  let fixedValue = value ?? '';
  return [
    {
      type: 'paragraph',
      children: [
        { text: fixedValue.replace(/[\n\r]/g, '') },
      ],
    },
  ];
};
